body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(231, 229, 225);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(146, 146, 164);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none;  /* creates padding around scroll thumb */
}