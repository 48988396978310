.contactbanner{
    width: 100%;
    height: 250px !important;
    position: relative;
    border: none;
}
.contactbanner::before{
    content: "";
    /* background-image: url(../../public/images/skycrane.jpg); */
    background-image: url(../../public/images/aboutImg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    inset: 0;
  
  }
.contactbanner>.innerDiv{
    width: 100%;
    height: 100% !important;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.contactbanner>.innerDiv>h1{
   font-size: 40px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: white;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
}
.callImg{
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}
.p{
    font-size: 18px;
    padding-left: 100px;
    padding-right: 100px;
}
.inputBox{
    width: 100%;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    outline: none;
    border: none;
    background-color: #ffffff;
    border: 1px solid rgb(212, 210, 210);

}

.textArea{
    width: 100%;
    height: 250px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    outline: none;
    border: none;
    background-color: #ffffff;
    border: 1px solid rgb(212, 210, 210);
}

.contactHead{
    font-size: 23px;
    font-weight: 700;
    width: 100%;
    font-family: "Roboto", sans-serif;
    color: gray;
    text-align: left;
  }
  .contactHead2{
    font-weight: 700;
    width: 100%;
    text-align: left;
    font-size: 32px !important;
    color: #0061D1;
    text-transform: capitalize;
  }
  .card-title{
    font-size: 17px;
  }
  .contactCard{
    width: 300px;
    padding: 10px;
    margin-top: 10px;
  }
  .formHead{
    font-weight: 700;
    width: 100%;
    text-align: center;
    font-size: 32px !important;
    color: #0061D1;
    text-transform: capitalize;
  }

@media only screen and (max-width: 799px) {
    .contactbanner{
        height: 200px !important;
    }
    .contactbanner>.innerDiv{
        height: 100% !important;
        text-align: left !important;
    }
    .contactbanner>.innerDiv>h1{
        width: 100% !important;
    }
    .contactbanner>.innerDiv>h1{
        padding: 0%;
        margin: 0% !important;
        font-size: 40px;
        width: 100%;
        text-align: left !important;
    }
    .breadcrumb-item>a{
        font-size: 16px;
 
    }
    .p{
        font-size: 17px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .contactbanner{
        width: 100%;
        height: 250px;
        position: relative;
    }
    .contactUsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contactCard{
        width: 100%;
        text-align: center;
    }
  .para{
    text-align: center;
  }
}
