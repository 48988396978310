.menuDot{
border-radius: 5px;
font-size: 30px;
margin-left: 20px;
padding: 5px;
border: 1px solid;
}
.textInput{
    width: 300px;
    height: 35px;
}
.label{
    font-size: 14px;
}
option{
    color: rgb(115, 111, 111);
    font-size: 15px;
}
.buttons{
    display: flex;

}
table th {
    background-color: #3675b7 !important; /* Change the background color */
    color: #ffffff; /* Change the text color */
    text-align: left; /* Center-align the text */
    font-weight: 600; /* Make the text bold */
    padding: 15px; /* Adjust the padding */
    font-family: "Poppins", sans-serif;
  }
  
.activeBtn{
    padding: 4px;
    outline: none;
    border-radius: 6px;
    border:none;
}

.label1{
    font-size: 16px;
    font-weight: 600;
}
.input1{
    width: 300px;
    height: 35px;
    padding-left: 10px;
    margin-left: 40px;
}

.datepicker{
    width: 250px;
    height: 35px;
    outline:'none';
    border-radius: 3px;
    margin-top: 5px;
    padding-left: 10px;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    height: 20px;
    --c:no-repeat radial-gradient(farthest-side,#000 93%,#0000);
    background:
      var(--c) 0    0,
      var(--c) 50%  0,
      var(--c) 100% 0;
    background-size: 8px 8px;
    position: relative;
    animation: l4-0 1s linear infinite alternate;
  }
  .loader:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    background: #000;
    left: 0;
    top: 0;
    animation: 
      l4-1 1s  linear infinite alternate,
      l4-2 0.5s cubic-bezier(0,200,.8,200) infinite;
  }
  @keyframes l4-0 {
    0%      {background-position: 0  100%,50% 0   ,100% 0}
    8%,42%  {background-position: 0  0   ,50% 0   ,100% 0}
    50%     {background-position: 0  0   ,50% 100%,100% 0}
    58%,92% {background-position: 0  0   ,50% 0   ,100% 0}
    100%    {background-position: 0  0   ,50% 0   ,100% 100%}
  }
  @keyframes l4-1 {
    100% {left:calc(100% - 8px)}
  }
  @keyframes l4-2 {
    100% {top:-0.1px}
  }

/* .custom-offcanvas .offcanvas-end{
    width: 700px;
} */

  @media only screen and (max-width: 799px) {
th,td{
    font-size: 12px;
    border: 1px solid rgb(56, 87, 198);
}

  }