.myContainer2{
    width: 100%;
    min-height: 85vh;
    position: relative;

}
.myContainer2::before{
    content: "";
    /* background-image: url(../../public/images/graybg2.jpg); */
    background-color: #0061D1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    inset: 0;
  
  }
  .loginImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.logoImg{
    width: 100px;
    height: 70px;
}
.myInput1{
    width: 400px;
    border: 1px solid rgb(200, 204, 208);
    height: 45px;
    outline: none;
    padding-left: 10px;
    color: gray;
    font-weight: 600;
    margin-top: 25px;
    background-color: aliceblue;
}
.myInput1:focus{
    border: 1px solid blueviolet;
}
.button1{
    width: 400px;
   border: none;
    height: 45px;
    outline: none;
    padding-left: 10px;
    color: white;
    font-weight: 600;
    margin-top: 25px;
    background-color: rgb(34, 115, 186);
}
.myInput2{
    width: 300px;
    border: 1px dashed rgb(200, 204, 208);
    border-color: black;
    height: 45px;
    outline: none;
    padding-left: 10px;
    color: rgb(13, 10, 10);
    font-weight: 600;
    margin-top: 25px;
    background-color: rgb(203, 208, 212);
}
.leftCol{
  border-radius: 20px;
    border: 1px solid;
    padding: 20px;
}
.myCol1{
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}