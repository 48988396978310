*{
    padding: 0;margin: 0;
    box-sizing: border-box;
}
.myContainer3{
    width: 100%;
   min-height: 92vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.myContainer3::before{
    content: "";
    /* background-image: url(../../public/images/graybg2.jpg); */
    background-color: #0061D1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    inset: 0;
  
  }
  .myRow2{
    isolation: isolate;
  }
.emailCard{
    width: 600px;
    height: 350px;

 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
background-color:rgb(239, 235, 235);
 color: #0061D1;
}
.passwordCard{
    padding-top: 30px;
    padding-bottom: 30px;
    width: 650px;
    height: auto;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
background-color:rgb(239, 235, 235);
 color: #0061D1;
}
.myInput1{
    width: 100%;
    border: 1px solid rgb(200, 204, 208);
    height: 40px;
    outline: none;
    padding-left: 10px;
    color: gray;
    font-weight: 600;
    margin-top: 10px;
}