.banner {
  width: 100%;
  height: 500px;
  position: relative;
}

.banner::before {
  content: "";
  /* background-image: url(../../public/images/skycrane.jpg); */
  background-image: url(../../public/images/bannerImg.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  inset: 0;
filter: brightness(40%);
/* filter: blur(3px); */

}

.whatsapp-button {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: #25D366; /* WhatsApp green */
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it's above other content */
  transition: transform 0.3s ease;
width: 50px;
height: 50px;
}

.whatsapp-button:hover {
  transform: scale(1.1); /* Scale up on hover */
}
.wpImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.whatsapp-link i {
  font-size: 24px;
  margin-right: 10px;
}


.innerDiv {
  isolation: isolate;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-left: 40px;
}
.associateDiv{
  position: absolute;
right: 40px;
top: 440px;
  background-color:#0061D1;
  display: flex;
  height: 170px;
  color: white;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  width: 500px;
}
.associateDiv>.left,.associateDiv>.right{
width: 45%;
height: 70%;
align-items: center;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.btns {
  display: flex;
}

.serviceBtn {
  padding: 7px 7px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  background-color: #0061D1;
  color: white;
  font-weight: 600;
  margin: 15px;
  outline: none;
  border: none;
  width: 150px;
  height: 40px;
}
.contactBtn{
  padding: 7px 7px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: white;
  font-weight: 700;
  margin: 15px;
  outline: none;
  border: none;
  width: 150px;
  height: 40px;
}
.contactBtn:hover{
  border: 1px solid white;
}
.quickContact{
  width: 800px;
  display: flex;
  justify-content: space-around;

}
.quickContact>.contactDetail,.quickContact>.locations{
  width: 48%;
  height: 120px;

  display: flex;

}
.quickContact>.contactDetail>.icon,.quickContact>.locations>.icon{
  width: 20%;
  height: 100%;
  display:flex ;
  justify-content: center;
  align-items: center;
}
.quickContact>.contactDetail>.right,.quickContact>.locations>.right{
  width: 80%;
  height: 100%;
  display:flex ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.quickContact>.contactDetail>.right>h5,.quickContact>.locations>.right>h5{
  font-weight: 700;
  width: 100%;
  text-align: left;
}
.quickContact>.contactDetail>.right>p,.quickContact>.locations>.right>p{

  width: 100%;
  text-align: left;
  padding: 0%;
  margin: 0%;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #777F78;
}

.firstPart>.right>.row>h5{
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  text-align: left;
  color: #777F78;
}
.forHeading1{
  font-weight: 700;
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #0061D1;
}
.forHeading5{
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  text-align: left;
  color: #777F78;
}
.firstPart>.right>.row>h1{
  font-weight: 700;
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #0061D1;
  font-family: "Open Sans", sans-serif;

}
.h6{
  font-weight: 700;
}

.para{
  width: 100%;
  text-align: justify;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #777F78;
}






.innerDiv>h4 {
  font-size: 35px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: rgb(255, 255, 255);
  width: 100%;
  /* text-shadow: 3px 2px 2px rgba(0, 0, 0, 1); */
  text-align: left;
}

.innerDiv>.h1 {
  font-size: 50px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: white;
  width: 90%;
  /* text-shadow: 3px 2px 2px rgba(0, 0, 0, 1); */
  text-align: left;
  text-transform: uppercase;
  line-height: 1.5;

}



.cardd {
  width: 240px;
  height: 100px;
  position: relative;
  bottom: 60px;
  background-color: #0061D1;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  font-weight: 600;
  text-align: left;

}

.serviceBtn>button:hover {
  transform: translateY(-10%);
}

.contactBtn>button:hover {
  transform: translateY(-10%);
}

/* CSS */
.button89 {
  --b: 3px;
  /* border thickness */
  --s: .45em;
  /* size of the corner */
  --color: #ffffff;

  padding: 5px 10px;
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
  width: 150px;
  height: 50px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 100px;
}

.button89:hover,
.button89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button89:active {
  background: var(--color);
  color: #fff;
}

.firstPart {
  width: 100%;
  height: 600px;
  margin-top: 30px;
  display: flex;

}

.firstPart>.left {
  width: 45%;
  height: 100%;

}

.firstPart>.right {
  width: 55%;
  height: 100%;
  position: relative;
  padding-left: 50px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;

}

/* .firstPart>.right::before {
  content: "";
  background-image: url(../../public/images/skycrane.jpg); 
  background-image: url(../../public/images/mixer6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  opacity: 0.2;
  inset: 0;
  transform: scaleX(-1);

} */

.firstPart>.right>ul {
  text-align: left;
}

.aboutImg {
  width: 500px;
  height: 500px;
  object-fit: cover;
  isolation: isolate;
}

.firstPart>.right>h3 {
  text-align: left;
  color: #0061D1;
  font-weight: 600;
  font-size: 28px !important;
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
}

.firstPart>.right>p {
  text-align: left;
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: black;
  font-family: "Open Sans", sans-serif;
}

.firstPart>.right>ul>li {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: black;
}

.chooseUsPart {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chooseUsPart::before {
  content: "";
  /* background-image: url(../../public/images/skycrane.jpg); */
  background-image: url(../../public/images/cta-bg5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  inset: 0;
  transform: scaleX(-1);

}

.innerContainer {
  isolation: isolate;
  color: white;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading>h2 {
  padding: 0 40px;
  width: auto;

}

.hline {
  width: 80px;
  height: 3px;
  background-color: #0061D1;
  display: inline;

}

.reasons {
  isolation: isolate;
  width: 100%;
  display: flex !important;
  margin-top: 60px;
  justify-content: space-around;
}

.innerCard {
  background-color: #F6F5F2;
  width: 22%;
  padding: 20px;
  border-radius: 8px;
  /* transition: all 1s ease-in-out; */
}

.innerCard>p {
  margin-top: 40px;
  color: black;
  font-weight: 500;
}

/* .innerCard:hover{
    transform: rotateY(360deg);
} */
.hrline {
  width: 40px;
  height: 4px;
  background-color: #0061D1;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}

.quality:hover+.hrline {
  width: 250px;
}

.quality {
  color: #0061D1;
  font-weight: 600;
  font-size: 28px !important;
  font-family: "Open Sans", sans-serif;
  text-align: left;
}

.shield {
  font-size: 60px;
  color: #0061D1;
  overflow-x: hidden;

}

.shield:hover {
  color: #0061D1;
}

.col-md-10>ul {
  list-style-type: none;
  margin: 0%;
  padding: 0%;
}

.col-md-10>ul>li {
  text-align: left;
  margin-bottom: 20px;
}

.tick {
  margin-right: 10px;
  color: rgb(68, 68, 192);

}

.icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.serviceCard {
  width: 100%;
  height: 12rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.serviceCard:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.serviceHead {
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  text-align: center;
  color: #777F78;
}

.serviceHead2 {
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-size: 32px !important;
  color: #0061D1;
  text-transform: capitalize;
}

.servicePart>h5 {
  font-weight: 700;
}

.servicePart>p {
  font-size: 17px;
  color: gray;
  padding-bottom: 40px;
}

.employee {
  width: 305px;
  position: relative;
  bottom: 50px;

}



.serviceRow {
  height: 200px;
  background-color: rgb(45, 43, 43);
  color: rgb(254, 254, 254);
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.serviceRoww {
  height: 200px;
  background-color: rgb(45, 43, 43);
  color: rgb(254, 254, 254);
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.serviceHead2 {
  font-size: 28px;
}

.serviceRow1>h1 {
  font-size: 60px !important;
  font-weight: 700;
  color: #0061D1;
}

.serviceRow1>p {
  width: 100%;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #777F78;
}

.upperText>p {
  text-align: justify;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
  color: #777F78;

}

.upperText>h3 {
  color: #0061D1;
  font-weight: 600;
  font-size: 28px !important;
  font-family: "Open Sans", sans-serif;

}


@media only screen and (max-width: 799px) {

  .menus {
    color: red !important;
  }

  .innerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
  }

  .innerDiv>h4 {
    font-size: 25px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    width: 100%;
    text-shadow: 3px 2px 2px rgba(0, 0, 0, 1);
    text-align: center;
  }

  .innerDiv>h1 {
    font-size: 35px !important;
    width: 90% !important;
    text-align: center !important;

  }

  .serviceBtn,
  .contactBtn {
    width: 150px;
    height: 45px;
    background: linear-gradient(to right, #0061D1, gray);

  }

  .contactBtn:hover::before {
    width: 0%;
  }

  .serviceBtn:hover::before {
    width: 0%;
  }

  .button89 {
    width: 130px;
    height: 50px;
    margin-top: 50px !important;
  }

  .cardd {
    position: relative;
    left: 0%;
    width: 100%;
    height: 50px;
  }
  .cardd>h5{
    font-size: 13px;
  }

  .myCol2 {
    display: none !important;
  }

  .firstPart {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .firstPart>.left {
    width: 100%;
    height: auto;
  }

  .firstPart>.right {
    width: 100%;
    height: auto;
    margin-top: 0px;
    padding: 10px;
  }

  .firstPart>.right>h3 {
    font-size: 25px !important;
  }

  .firstPart>.right>p {
    text-align: justify;

  }

  .chooseUsPart {
    height: auto;
    margin-bottom: 0px;
  }

  .reasons {
    width: 90%;
    flex-wrap: wrap;
  }

  .innerCard {
    width: 48%;
    height: 230px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .serviceHead {
    font-size: 16px;
  }

  .serviceHead2 {
    width: 90%;
    font-size: 25px !important;
  }

  .serviceRow1>h1 {
    font-size: 30px !important;
  }

  .serviceRow1>p {
    font-size: 14px !important;
  }

  .servicePart {
    order: 2;
  }

  .servicePart>h5 {
    font-size: 16px;
  }
.para{
  font-size: 15px !important;
}
  .servicePart>p {
    font-size: 15px !important;
    text-align: justify;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .contactInfo>.content>p {
    font-size: 15px !important;
  }

  .contactInfo>.content>.div {
    font-size: 15px !important;
  }

  .contactInfo>.content>.div>.div1 {
    font-size: 15px;
  }

  .contactInfo>.content>.div>.div2 {
    font-size: 15px;
  }




  .associateDiv{
    display: none;
  }
  .quickContact{
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .quickContact>.contactDetail>.right,.quickContact>.locations.right{
    width: 100% !important;
    text-align: center;
  }
  .quickContact>.contactDetail,.quickContact>.locations{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 10px;

  }
  .quickContact>.contactDetail>.icon,.quickContact>.locations>.icon{
    width: 100%;
height: 60px;

  }
  .contactDetail>.right>h5,.locations>.right>h5{
    font-size: 16px;
    width: 100% !important;
 text-align: center !important;
  }
.quickContact>.contactDetail>.right>p,.quickContact>.locations>.right>p{
    font-size: 15px;
    text-align: center;

  }
  .aboutImg {
    width: 100%;
    height: 300px;

  }
  .forHeading1{
    text-align: center;
    font-size: 25px !important;
  }
  .forHeading5{
  text-align: center;
  font-size: 16px !important;
}
.whatsapp-button{
  display: none;
}
.rowone{
  margin-top: 20px;
}
.hideIt{
  display: none;
}

}